import dayjs from 'dayjs'
import { sortBy } from 'es-toolkit'
import { useCallback, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import type { BopWorkspaceData } from 'api/dashboard'

import { selectDashboardStatus } from 'slices/dashboardSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'

import { BOP_TYPE, ESTIMATE_POINT_PADDING } from 'components/Dashboard/utils'
import { DEFAULT_POINT_PADDING } from 'components/common/Chart/Chart'
import { createStackedChartOptions } from 'components/common/utils'

import type { SeriesOptionsType } from 'highcharts'

const BopGraphGroupType = {
  sales: 'sales',
  costs: 'costs',
  salesEstimate: 'salesEstimate',
  costsEstimate: 'costsEstimate',
}

type BopGraphDataType = {
  sales: number
  otherSales: number
  costOfGoodsSold: number
  fixedCosts: number
  otherCosts: number
}

type BopGraphDataProps = {
  data: BopGraphDataType[]
  estimate?: BopGraphDataType[]
}

const POINT_PLACEMENT = 0.075

export const useBopMonitoringTotalAndWorkspaces = (selectedBopType: string) => {
  const { bopMonitoring } = useSelector(selectDashboardStatus, shallowEqual)
  const { tenant } = useSelector(selectTenantsStatus, shallowEqual)

  const estimateData = useMemo(() => {
    return bopMonitoring && bopMonitoring.estimate
  }, [bopMonitoring])

  const getGraphSeriesData = useCallback((graphData: BopGraphDataProps): SeriesOptionsType[] => {
    const salesData = graphData.data.map(item => item.sales)
    const otherSalesData = graphData.data.map(item => item.otherSales)
    const costOfGoodsSoldData = graphData.data.map(item => item.costOfGoodsSold)
    const fixedCostsData = graphData.data.map(item => item.fixedCosts)
    const otherCostsData = graphData.data.map(item => item.otherCosts)

    const pointPlacement = graphData.estimate ? POINT_PLACEMENT : undefined // pointPlacementのデフォルト値はundefined

    // custom要素にtooltip表示用のデータを追加
    const salesSeriesData = salesData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.sales,
        totalSales: item + otherSalesData[index],
        sales: item,
        otherSales: otherSalesData[index],
      },
    }))
    const otherSalesSeriesData = otherSalesData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.sales,
        totalSales: salesData[index] + item,
        sales: salesData[index],
        otherSales: item,
      },
    }))
    const costOfGoodsSoldSeriesData = costOfGoodsSoldData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.costs,
        totalCosts: item + fixedCostsData[index] + otherCostsData[index],
        costOfGoodsSold: item,
        fixedCosts: fixedCostsData[index],
        otherCosts: otherCostsData[index],
      },
    }))
    const fixedCostsSeriesData = fixedCostsData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.costs,
        totalCosts: costOfGoodsSoldData[index] + item + otherCostsData[index],
        costOfGoodsSold: costOfGoodsSoldData[index],
        fixedCosts: item,
        otherCosts: otherCostsData[index],
      },
    }))
    const otherCostsSeriesData = otherCostsData.map((item, index) => ({
      y: item,
      custom: {
        type: BopGraphGroupType.costs,
        totalCosts: costOfGoodsSoldData[index] + fixedCostsData[index] + item,
        costOfGoodsSold: costOfGoodsSoldData[index],
        fixedCosts: fixedCostsData[index],
        otherCosts: item,
      },
    }))

    const series: SeriesOptionsType[] = [
      {
        type: 'column',
        name: '売上',
        color: 'var(--bs-primary)',
        data: salesSeriesData,
        stack: BopGraphGroupType.sales,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: 'その他売上',
        color: 'var(--bs-primary-middle)',
        data: otherSalesSeriesData,
        stack: BopGraphGroupType.sales,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: '売上原価',
        color: 'var(--bs-danger-stronger-middle)',
        data: costOfGoodsSoldSeriesData,
        stack: BopGraphGroupType.costs,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: '固定費',
        color: 'var(--bs-danger-middle)',
        data: fixedCostsSeriesData,
        stack: BopGraphGroupType.costs,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: 'その他費用',
        color: 'var(--bs-danger-pale)',
        data: otherCostsSeriesData,
        stack: BopGraphGroupType.costs,
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
    ]

    if (graphData.estimate) {
      const estimateSalesData = graphData.estimate.map(item => item.sales)
      const estimateOtherSalesData = graphData.estimate.map(item => item.otherSales)
      const estimateCostOfGoodsSoldData = graphData.estimate.map(item => item.costOfGoodsSold)
      const estimateFixedCostsData = graphData.estimate.map(item => item.fixedCosts)
      const estimateOtherCostsData = graphData.estimate.map(item => item.otherCosts)

      // 見込みのデータを挿入と追加
      series.splice(
        2,
        0,
        {
          type: 'column',
          name: '売上(見込み)',
          color: 'var(--bs-primary)',
          data: estimateSalesData,
          stack: BopGraphGroupType.salesEstimate,
          opacity: 0.5,
          pointPlacement: -1 * pointPlacement!,
          zIndex: 0,
        },

        {
          type: 'column',
          name: 'その他売上(見込み)',
          color: 'var(--bs-primary-middle)',
          data: estimateOtherSalesData,
          stack: BopGraphGroupType.salesEstimate,
          opacity: 0.5,
          pointPlacement: -1 * pointPlacement!,
        }
      )
      series.push(
        {
          type: 'column',
          name: '費用(見込み)',
          color: 'var(--bs-danger-stronger-middle)',
          data: estimateCostOfGoodsSoldData,
          stack: BopGraphGroupType.costsEstimate,
          opacity: 0.5,
          pointPlacement: -1 * pointPlacement!,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '固定費(見込み)',
          color: 'var(--bs-danger-middle)',
          data: estimateFixedCostsData,
          stack: BopGraphGroupType.costsEstimate,
          opacity: 0.5,
          pointPlacement: -1 * pointPlacement!,
        },
        {
          type: 'column',
          name: 'その他費用(見込み)',
          color: 'var(--bs-danger-pale)',
          data: estimateOtherCostsData,
          stack: BopGraphGroupType.costsEstimate,
          opacity: 0.4,
          pointPlacement: -1 * pointPlacement!,
        }
      )
    }

    return series
  }, [])

  const totalChartOptions = useMemo(() => {
    if (!bopMonitoring) {
      return
    }

    const displayData = selectedBopType === BOP_TYPE.ESTIMATE ? bopMonitoring.estimate : bopMonitoring.actual

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL && estimateData
        ? {
            data: [
              {
                sales: displayData.totalWorkspaceData.sales,
                otherSales: displayData.totalWorkspaceData.otherSales,
                costOfGoodsSold: displayData.totalWorkspaceData.costOfGoodsSold,
                fixedCosts: displayData.totalWorkspaceData.fixedCosts,
                otherCosts: displayData.totalWorkspaceData.otherCosts,
              },
            ],
            estimate: [
              {
                sales: estimateData.totalWorkspaceData.sales,
                otherSales: estimateData.totalWorkspaceData.otherSales,
                costOfGoodsSold: estimateData.totalWorkspaceData.costOfGoodsSold,
                fixedCosts: estimateData.totalWorkspaceData.fixedCosts,
                otherCosts: estimateData.totalWorkspaceData.otherCosts,
              },
            ],
          }
        : {
            data: [
              {
                sales: displayData.totalWorkspaceData.sales,
                otherSales: displayData.totalWorkspaceData.otherSales,
                costOfGoodsSold: displayData.totalWorkspaceData.costOfGoodsSold,
                fixedCosts: displayData.totalWorkspaceData.fixedCosts,
                otherCosts: displayData.totalWorkspaceData.otherCosts,
              },
            ],
          }

    const optionProps = {
      seriesData: getGraphSeriesData(graphData),
      categories: [tenant?.name || ''],
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }

    const options = createStackedChartOptions(optionProps)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }
      const tooltipText =
        this.point.options.custom!.type === 'sales'
          ? `
      <div style="text-align:right">売上合計：${this.point.options.custom.totalSales.toLocaleString()}円<br>
      売上：${this.point.options.custom.sales.toLocaleString()}円<br>
      その他売上：${this.point.options.custom.otherSales.toLocaleString()}円</div>`
          : `
      <div style="text-align:right">費用合計：${this.point.options.custom.totalCosts.toLocaleString()}円<br>
      売上原価：${this.point.options.custom.costOfGoodsSold.toLocaleString()}円<br>
      固定費：${this.point.options.custom.fixedCosts.toLocaleString()}円<br>
      その他費用：${this.point.options.custom.otherCosts.toLocaleString()}円</div>`

      return tooltipText
    }

    return options
  }, [bopMonitoring, estimateData, getGraphSeriesData, selectedBopType, tenant?.name])

  const workspaceChartOptions = useMemo(() => {
    if (!bopMonitoring) {
      return
    }

    const displayData = selectedBopType === BOP_TYPE.ESTIMATE ? bopMonitoring.estimate : bopMonitoring.actual
    const sortedWorkspaces = sortBy(displayData.workspaces, [
      workspace => workspace.data.sales + workspace.data.otherSales,
    ]).reverse()

    const sortedEstimateData = (
      sortedWorkspacesData: BopWorkspaceData[],
      estimateWorkspacesData: BopWorkspaceData[]
    ) => {
      return sortedWorkspacesData.map(workspace => estimateWorkspacesData.find(item => item.id === workspace.id))
    }

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL && estimateData
        ? {
            data: sortedWorkspaces.map(workspace => {
              return {
                sales: workspace.data.sales,
                otherSales: workspace.data.otherSales,
                costOfGoodsSold:
                  workspace.data.materialCosts +
                  workspace.data.variableDirectLaborCosts +
                  workspace.data.variableIndirectLaborCosts,
                fixedCosts: workspace.data.indirectLaborCosts + workspace.data.managementCosts,
                otherCosts: workspace.data.workspaceCosts + workspace.data.tenantCostsWithWorkspace,
              }
            }),
            estimate: sortedEstimateData(sortedWorkspaces, estimateData.workspaces).map(workspace => {
              return {
                sales: workspace!.data.sales, // workspaceがundefinedであることはないが、sortedEstimateData関数の返り値はundefinedを許容するため、workspace!としている
                otherSales: workspace!.data.otherSales,
                costOfGoodsSold:
                  workspace!.data.materialCosts +
                  workspace!.data.variableDirectLaborCosts +
                  workspace!.data.variableIndirectLaborCosts,
                fixedCosts: workspace!.data.indirectLaborCosts + workspace!.data.managementCosts,
                otherCosts: workspace!.data.workspaceCosts + workspace!.data.tenantCostsWithWorkspace,
              }
            }),
          }
        : {
            data: sortedWorkspaces.map(workspace => {
              return {
                sales: workspace.data.sales,
                otherSales: workspace.data.otherSales,
                costOfGoodsSold:
                  workspace.data.materialCosts +
                  workspace.data.variableDirectLaborCosts +
                  workspace.data.variableIndirectLaborCosts,
                fixedCosts: workspace.data.indirectLaborCosts + workspace.data.managementCosts,
                otherCosts: workspace.data.workspaceCosts + workspace.data.tenantCostsWithWorkspace,
              }
            }),
          }

    const optionProps = {
      seriesData: getGraphSeriesData(graphData),
      categories: sortedWorkspaces.map(workspace => workspace.name),
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }

    const options = createStackedChartOptions(optionProps)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }
      const tooltipText =
        this.point.options.custom!.type === 'sales'
          ? `
      <div style="text-align:right">売上合計：${this.point.options.custom.totalSales.toLocaleString()}円<br>
      売上：${this.point.options.custom.sales.toLocaleString()}円<br>
      その他売上：${this.point.options.custom.otherSales.toLocaleString()}円</div>`
          : `
      <div style="text-align:right">費用合計：${this.point.options.custom.totalCosts.toLocaleString()}円<br>
      売上原価：${this.point.options.custom.costOfGoodsSold.toLocaleString()}円<br>
      固定費：${this.point.options.custom.fixedCosts.toLocaleString()}円<br>
      その他費用：${this.point.options.custom.otherCosts.toLocaleString()}円</div>`

      return tooltipText
    }

    return options
  }, [bopMonitoring, estimateData, getGraphSeriesData, selectedBopType])

  const updatedAtBop = useMemo(() => {
    return bopMonitoring?.updatedAt && dayjs(bopMonitoring.updatedAt).format('YYYY/MM/DD HH:mm:ss')
  }, [bopMonitoring])

  return { totalChartOptions, workspaceChartOptions, updatedAtBop }
}
