import * as React from 'react'

import type { MergedWorkspaceData } from 'hooks/useBopProfitAndLoss'

import { BopColumn, BopColumnTitle } from './BopColumn'

import styles from './BopProfitAndLoss.module.scss'

type BopTableProps = {
  bopCols: MergedWorkspaceData[]
  isCheckedEstimate: boolean
}

const BopTable: React.FC<BopTableProps> = ({ bopCols, isCheckedEstimate }) => {
  // bopColsから合計を算出する
  const sumCol = React.useMemo(() => {
    if (bopCols.length === 0) {
      return []
    }
    const initTotal = bopCols[0].data.map(item => ({
      actualValue: 0,
      estimateValue: 0,
      className: item.className,
    }))
    return bopCols.reduce((acc, item) => {
      return acc.map((dataItem, index) => ({
        actualValue: dataItem.actualValue + item.data[index].actualValue,
        estimateValue: dataItem.estimateValue + item.data[index].estimateValue,
        className: dataItem.className,
      }))
    }, initTotal)
  }, [bopCols])

  return (
    <div className="d-flex mb-3">
      <div className={styles.leftBlock}>
        <BopColumnTitle />
      </div>
      <div className={styles.centerBlock}>
        {bopCols.map((item, index) => (
          <BopColumn key={index} title={item.title} data={item.data} isCheckedEstimate={isCheckedEstimate} />
        ))}
      </div>
      <div className={styles.rightBlock}>
        <BopColumn title="合計" data={sumCol} isCheckedEstimate={isCheckedEstimate} />
      </div>
    </div>
  )
}

export default BopTable
