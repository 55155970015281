import { sortBy } from 'es-toolkit'
import { floor } from 'es-toolkit/compat'
import { useCallback, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import type { BopWorkspaceData } from 'api/dashboard'

import { selectDashboardStatus } from 'slices/dashboardSlice'

import { BOP_TYPE, ESTIMATE_POINT_PADDING } from 'components/Dashboard/utils'
import { DEFAULT_POINT_PADDING } from 'components/common/Chart/Chart'
import { createStackedChartOptions } from 'components/common/utils'

import type { SeriesOptionsType } from 'highcharts'

type BopGraphDataProfitMarginProps = {
  data: number[]
  estimate?: number[]
}

const CUSTOM_TYPE = {
  PROFIT_MARGIN: 'profitMargin',
  ESTIMATE_PROFIT_MARGIN: 'estimateProfitMargin',
} as const

type CustomType = (typeof CUSTOM_TYPE)[keyof typeof CUSTOM_TYPE]

type CustomTooltipData = {
  type: CustomType
  profitMargin: number
}

type ChartPointData = {
  y: number
  color: string
  custom: CustomTooltipData
}

const POINT_PLACEMENT = 0.15

export const useBopMonitoringProfitMargin = (selectedBopType: string) => {
  const { bopMonitoring } = useSelector(selectDashboardStatus, shallowEqual)

  const estimateData = useMemo(() => {
    return bopMonitoring && bopMonitoring.estimate
  }, [bopMonitoring])

  const getGraphSeriesDataProfitMargin = useCallback(
    (graphData: BopGraphDataProfitMarginProps): SeriesOptionsType[] => {
      const pointPlacement = graphData.estimate ? POINT_PLACEMENT : undefined // pointPlacementのデフォルト値はundefined

      // custom要素にtooltip表示用のデータを追加
      const profitMarginSeriesData: ChartPointData[] = graphData.data.map(item => ({
        y: item,
        color: item >= 0 ? 'var(--bs-success)' : 'var(--bs-danger)',
        custom: {
          type: CUSTOM_TYPE.PROFIT_MARGIN,
          profitMargin: item,
        },
      }))

      const series: SeriesOptionsType[] = [
        {
          type: 'column',
          name: '利益率',
          data: profitMarginSeriesData,
          stack: CUSTOM_TYPE.PROFIT_MARGIN,
          pointPlacement: pointPlacement,
          zIndex: 1,
        },
      ]

      if (graphData.estimate) {
        const estimateProfitMarginData: ChartPointData[] = graphData.estimate.map(item => ({
          y: item,
          color: item >= 0 ? 'var(--bs-success)' : 'var(--bs-danger)',
          custom: {
            type: CUSTOM_TYPE.ESTIMATE_PROFIT_MARGIN,
            profitMargin: item,
          },
        }))

        // 見込みのデータを挿入と追加
        series.splice(2, 0, {
          type: 'column',
          name: '売上(見込み)',
          data: estimateProfitMarginData,
          stack: CUSTOM_TYPE.ESTIMATE_PROFIT_MARGIN,
          opacity: 0.5,
          pointPlacement: -1 * pointPlacement!,
          zIndex: 0,
        })
      }

      return series
    },
    []
  )

  const profitMarginChartOptions = useMemo(() => {
    if (!bopMonitoring) {
      return
    }

    const displayData = selectedBopType === BOP_TYPE.ESTIMATE ? bopMonitoring.estimate : bopMonitoring.actual

    // 共通関数
    const calculateProfitMargin = (data: BopWorkspaceData['data']): number => {
      const totalSales = data.sales + data.otherSales
      const totalCosts =
        data.materialCosts +
        data.variableDirectLaborCosts +
        data.variableIndirectLaborCosts +
        data.indirectLaborCosts +
        data.managementCosts +
        data.workspaceCosts +
        data.tenantCostsWithWorkspace

      return totalSales > 0 ? floor(((totalSales - totalCosts) / totalSales) * 100, 2) : 0
    }

    // ワークスペースを利益率順にソート
    const workspacesWithProfitMargin = displayData.workspaces.map((workspace: BopWorkspaceData) => ({
      ...workspace,
      profitMargin: calculateProfitMargin(workspace.data),
    }))

    const sortedWorkspaces = sortBy(workspacesWithProfitMargin, ['profitMargin']).reverse()

    // sortedWorkspacesData順に合わせて、estimateWorkspacesDataもソート
    const sortedEstimateData = (sortedWorkspacesData: BopWorkspaceData[], estimateWorkspacesData: BopWorkspaceData[]) =>
      sortedWorkspacesData.map(workspace => estimateWorkspacesData.find(item => item.id === workspace.id))

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL && estimateData
        ? {
            // 実績表示、且つ、estimateDataが存在する時（実績データ＋見積データ）
            data: sortedWorkspaces.map(workspace => calculateProfitMargin(workspace.data)),
            estimate: sortedEstimateData(sortedWorkspaces, estimateData.workspaces).map(
              workspace => calculateProfitMargin(workspace!.data) // workspaceがundefinedであることはないが、sortedEstimateData関数の返り値はundefinedを許容するため、workspace!としている
            ),
          }
        : {
            // 見積もり表示、または、estimateDataが存在しない時（見積データ）
            data: sortedWorkspaces.map(workspace => calculateProfitMargin(workspace.data)),
          }

    const optionProps = {
      seriesData: getGraphSeriesDataProfitMargin(graphData),
      categories: sortedWorkspaces.map(workspace => workspace.name), // ワークスペース名だけを抽出
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }

    const options = createStackedChartOptions(optionProps)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }

      if (this.point.options.custom.type === CUSTOM_TYPE.ESTIMATE_PROFIT_MARGIN) {
        return false // 見込みデータのツールチップを非表示
      }

      const tooltipText = `<div style="text-align:right">利益率：${this.point.options.custom.profitMargin.toLocaleString()}%</div>`
      return tooltipText
    }

    return options
  }, [bopMonitoring, estimateData, getGraphSeriesDataProfitMargin, selectedBopType])

  return { profitMarginChartOptions }
}
