import * as React from 'react'

import type { ColorType } from 'components/common/types'

import styles from './styles.module.scss'

export type BadgeItem = {
  color: ColorType
  key: number
  label: string
  disabled?: boolean
  bgColor?: string
}

type Props = {
  items: BadgeItem[]
  selected?: number[]
  onChange?: (selected: number[]) => void
  hideColor?: boolean
  name?: string // APMを判別するための文字列
}

const isTablet =
  navigator.userAgent.includes('Android') ||
  navigator.userAgent.includes('iPad') ||
  navigator.userAgent.includes('iPhone')

const BadgeButton: React.FC<Props> = props => {
  const { items, selected = [], onChange = () => {}, hideColor = false, name } = props

  const onGraphBadge = (key: number, disabled: boolean) => {
    if (!disabled) {
      const newList = selected.some(item => item === key) ? selected.filter(item => item !== key) : [...selected, key]
      onChange(newList)
    }
  }

  const active = (key: number, disabled: boolean) => {
    if (disabled) {
      return styles.toggle_button_disabled
    }
    if (selected.some(item => item === key)) {
      return styles.toggle_button_active
    }
    return isTablet ? styles.toggle_button_tablet : styles.toggle_button
  }

  return (
    <>
      {items.map(({ color, key, label, disabled = false, bgColor }) => (
        <div
          key={key}
          className={`d-flex align-items-center w-auto ${active(key, disabled)}`}
          onClick={() => onGraphBadge(key, disabled)}
          data-transaction-name={name}
        >
          {!hideColor && <div className={`${styles.square} bg-${bgColor ?? color} me-1`}></div>}
          <div className="text-truncate">{label}</div>
        </div>
      ))}
    </>
  )
}

export default BadgeButton
