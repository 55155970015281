import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardTitle, Input, Label } from 'reactstrap'

import { getBopReportsProfitAndLoss, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import BopTable from 'components/common/BopReports/BopTable'
import { UpdateLabel } from 'components/common/UpdateLabel/UpdateLabel'

import useBopProfitAndLoss from 'hooks/useBopProfitAndLoss'
import { useBopReportsQuery } from 'hooks/useBopReportsQuery'
import useBusinessTime from 'hooks/useBusinessTime'

import { BopReportsCommon } from './BopReportsCommon'

import type { ChangeEvent } from 'react'

export const BopReportsProfitAndLoss = () => {
  const [dataType, setDataType] = useState<'conjecturedEstimates' | 'actuals'>('conjecturedEstimates')
  const [isCheckedEstimate, setIsCheckedEstimate] = useState(true)

  const { queryStart, queryEnd } = useBopReportsQuery()
  const { formatUpdatedAt, processProfitAndLossData } = useBopProfitAndLoss()
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { bopReports } = useSelector(selectBopReportsStatus, shallowEqual)
  const workspaceIds = useMemo(() => partialWorkspaces.map(w => w.id), [partialWorkspaces])
  const dispatch = useDispatch()
  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    dispatch(getBopReportsProfitAndLoss(getWorkDate(queryStart), getWorkDate(queryEnd)))
  }, [dispatch, queryStart, queryEnd, getWorkDate])

  const bopCols = useMemo(() => {
    if (!bopReports) {
      return []
    }
    return processProfitAndLossData(bopReports[dataType], bopReports.estimates)
  }, [bopReports, dataType, processProfitAndLossData])

  const handleConjecturedEstimatesChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDataType(e.target.checked ? 'conjecturedEstimates' : 'actuals')
  }, [])

  return (
    <BopReportsCommon selectedWorkspaceIds={workspaceIds}>
      <Card className="mt-2">
        <CardBody className="p-4">
          <CardTitle className="mb-3 fw-bold font-large">損益詳細</CardTitle>

          <div className="d-flex mb-3">
            <div className="form-check me-3">
              <Input
                className="form-check-input"
                id="estimate"
                checked={isCheckedEstimate}
                type="checkbox"
                onChange={e => setIsCheckedEstimate(e.target.checked)}
              />
              <Label className="form-check-label" for="estimate">
                見込表示
              </Label>
            </div>

            <div className="form-check">
              <Input
                className="form-check-input"
                id="conjectured-estimates"
                checked={dataType === 'conjecturedEstimates'}
                type="checkbox"
                onChange={handleConjecturedEstimatesChange}
              />
              <Label className="form-check-label" for="conjectured-estimates">
                見込を含んだ予測表示
              </Label>
            </div>
          </div>

          <BopTable bopCols={bopCols} isCheckedEstimate={isCheckedEstimate} />

          <UpdateLabel updatedAt={formatUpdatedAt(bopReports?.updatedAt)} />
        </CardBody>
      </Card>
    </BopReportsCommon>
  )
}
