type Props = {
  updatedAt?: string
}

export const UpdateLabel = ({ updatedAt }: Props) =>
  updatedAt && (
    <div className="d-flex text-muted justify-content-end">
      <i className="icf-updated align-self-center pe-1" />
      <div className="font-x-small">{updatedAt}</div>
    </div>
  )
