import axios from 'axios'

import type {
  BopMonitoringReportParam,
  BopReportsBopResponse,
  BopReportsLaborCostsResponse,
  BopReportsProfitAndLossResponse,
  BopReportsUnitCostsResponse,
  BopReportUnitCostsParam,
  CheckExportDataReadyResponse,
  CreateBopExportDataInfo,
  CreateExportDataResponse,
} from 'api/bop_reports/types'

import type { CommonParams } from 'slices/utils'

export const getBopReportsProfitAndLoss = (
  params: CommonParams,
  from: string,
  to: string
): Promise<BopReportsProfitAndLossResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-report-detail?from=${from}&to=${to}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopReportsBop = (
  params: CommonParams,
  data: BopMonitoringReportParam
): Promise<BopReportsBopResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-report`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopReportsLaborCosts = (
  params: CommonParams,
  data: BopMonitoringReportParam
): Promise<BopReportsLaborCostsResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-report-labor-costs`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getBopReportsUnitCosts = (
  params: CommonParams,
  data: BopReportUnitCostsParam
): Promise<BopReportsUnitCostsResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-report-unit-costs`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createBopExportData = (
  params: CommonParams,
  data: CreateBopExportDataInfo
): Promise<CreateExportDataResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-export/async`

    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, data, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getBopExportData = (params: CommonParams, requestId: string): Promise<CheckExportDataReadyResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v2/tenants/${params.tenantId}/bop-export/update-status/${requestId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
