import { sortBy } from 'es-toolkit'
import { useMemo, useCallback } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import type { BopKeyScheduleTypeData } from 'api/dashboard'

import { selectDashboardStatus } from 'slices/dashboardSlice'
import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import { BOP_TYPE, ESTIMATE_POINT_PADDING } from 'components/Dashboard/utils'
import { DEFAULT_POINT_PADDING } from 'components/common/Chart/Chart'
import { createStackedChartOptions } from 'components/common/utils'

import type { SeriesOptionsType } from 'highcharts'

type BopGraphDataUnitCostsProps = {
  data: {
    unit: string
    unitCosts: number
    variableDirectLaborCosts: number
    variableIndirectLaborCosts: number
    materialCosts: number
    variableDirectLaborCostsValue: number
    variableIndirectLaborCostsValue: number
    materialCostsValue: number
  }[]
  estimate?: {
    unit: string
    unitCosts: number
    variableDirectLaborCosts: number
    variableIndirectLaborCosts: number
    materialCosts: number
    variableDirectLaborCostsValue: number
    variableIndirectLaborCostsValue: number
    materialCostsValue: number
  }[]
}

const POINT_PLACEMENT = 0.15

export const useBopMonitoringUnitCosts = (selectedBopType: string, isManHour: boolean) => {
  const { bopMonitoring } = useSelector(selectDashboardStatus, shallowEqual)
  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const estimateData = useMemo(() => {
    return bopMonitoring && bopMonitoring.estimate
  }, [bopMonitoring])

  const getGraphSeriesDataUnitCosts = useCallback((graphData: BopGraphDataUnitCostsProps): SeriesOptionsType[] => {
    const unitCosts = graphData.data.map(item => item.unitCosts)
    const variableDirectLaborCosts = graphData.data.map(item => item.variableDirectLaborCosts)
    const variableIndirectLaborCosts = graphData.data.map(item => item.variableIndirectLaborCosts)
    const materialCosts = graphData.data.map(item => item.materialCosts)
    const variableDirectLaborCostsValue = graphData.data.map(item => item.variableDirectLaborCostsValue)
    const variableIndirectLaborCostsValue = graphData.data.map(item => item.variableIndirectLaborCostsValue)
    const materialCostsValue = graphData.data.map(item => item.materialCostsValue)
    const pointPlacement = graphData.estimate ? POINT_PLACEMENT : undefined // pointPlacementのデフォルト値はundefined

    // custom要素にtooltip表示用のデータを追加
    const variableDirectLaborCostsSeriesData = variableDirectLaborCostsValue.map((item, index) => ({
      y: item,
      color: 'var(--bs-danger-stronger-middle)',
      custom: {
        type: 'variableDirectLaborCosts',
        unit: graphData.data[index].unit,
        unitCosts: unitCosts[index],
        variableDirectLaborCosts: variableDirectLaborCosts[index],
        variableIndirectLaborCosts: variableIndirectLaborCosts[index],
        materialCosts: materialCosts[index],
      },
    }))
    const variableIndirectLaborCostsSeriesData = variableIndirectLaborCostsValue.map((item, index) => ({
      y: item,
      color: 'var(--bs-danger-middle)',
      custom: {
        type: 'variableIndirectLaborCosts',
        unit: graphData.data[index].unit,
        unitCosts: unitCosts[index],
        variableDirectLaborCosts: variableDirectLaborCosts[index],
        variableIndirectLaborCosts: variableIndirectLaborCosts[index],
        materialCosts: materialCosts[index],
      },
    }))
    const materialCostsSeriesData = materialCostsValue.map((item, index) => ({
      y: item,
      color: 'var(--bs-light-gray)',
      custom: {
        type: 'materialCosts',
        unit: graphData.data[index].unit,
        unitCosts: unitCosts[index],
        variableDirectLaborCosts: variableDirectLaborCosts[index],
        variableIndirectLaborCosts: variableIndirectLaborCosts[index],
        materialCosts: materialCosts[index],
      },
    }))

    const series: SeriesOptionsType[] = [
      {
        type: 'column',
        name: '変動直接労務費',
        data: variableDirectLaborCostsSeriesData,
        stack: 'unitCosts',
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: '変動間接労務費',
        data: variableIndirectLaborCostsSeriesData,
        stack: 'unitCosts',
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
      {
        type: 'column',
        name: '資材・材料費',
        data: materialCostsSeriesData,
        stack: 'unitCosts',
        pointPlacement: pointPlacement,
        zIndex: 1,
      },
    ]

    if (graphData.estimate) {
      const estimateVariableDirectLaborCostsData = graphData.estimate.map(item => item.variableDirectLaborCostsValue)
      const estimateVariableIndirectLaborCostsData = graphData.estimate.map(
        item => item.variableIndirectLaborCostsValue
      )
      const estimateMaterialCostsData = graphData.estimate.map(item => item.materialCostsValue)

      // 見込みのデータを挿入と追加
      series.splice(
        2,
        0,
        {
          type: 'column',
          name: '変動直接労務費',
          color: 'var(--bs-danger-stronger-middle)',
          data: estimateVariableDirectLaborCostsData,
          stack: 'estimateUnitCosts',
          opacity: 0.2,
          pointPlacement: -1 * pointPlacement!,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '変動間接労務費',
          color: 'var(--bs-danger-middle)',
          data: estimateVariableIndirectLaborCostsData,
          stack: 'estimateUnitCosts',
          opacity: 0.2,
          pointPlacement: -1 * pointPlacement!,
          zIndex: 0,
        },
        {
          type: 'column',
          name: '資材・材料費',
          color: 'var(--bs-light-gray)',
          data: estimateMaterialCostsData,
          stack: 'estimateUnitCosts',
          opacity: 0.2,
          pointPlacement: -1 * pointPlacement!,
          zIndex: 0,
        }
      )
    }

    return series
  }, [])

  const unitCostsChartOptions = useMemo(() => {
    if (!bopMonitoring) {
      return
    }

    const displayData = selectedBopType === BOP_TYPE.ESTIMATE ? bopMonitoring.estimate : bopMonitoring.actual

    const sortedKeyScheduleTypes = sortBy(displayData.keyScheduleTypes, [
      (keyScheduleType: BopKeyScheduleTypeData) => keyScheduleType.data.amountUnitCosts,
    ])

    const sortedEstimateData = (
      sortedKeyScheduleTypesData: BopKeyScheduleTypeData[],
      estimateKeyScheduleTypesData: BopKeyScheduleTypeData[]
    ) => {
      return sortedKeyScheduleTypesData.map(workspace =>
        estimateKeyScheduleTypesData.find(item => item.id === workspace.id)
      )
    }

    // グラフに必要な高さ（＝金額の値、人時の値）を算出
    const calculateCostValue = (unitCosts: number, percentage: number): number =>
      Math.floor(unitCosts * (percentage / 100))

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL && estimateData
        ? {
            // 実績表示、且つ、estimateDataが存在する時（実績データ＋見積データ）
            data: sortedKeyScheduleTypes.map(workspace => {
              const unit = allScheduleTypes.find(item => item.id === workspace.id)?.unit || ''
              const unitCosts = isManHour ? workspace.data.hourlyWorkerUnitCosts : workspace.data.amountUnitCosts
              const variableDirectLaborCosts = isManHour
                ? workspace.data.hourlyWorkerVariableDirectLaborCosts
                : workspace.data.amountVariableDirectLaborCosts
              const variableIndirectLaborCosts = isManHour
                ? workspace.data.hourlyWorkerVariableIndirectLaborCosts
                : workspace.data.amountVariableIndirectLaborCosts
              const materialCosts = isManHour ? 0 : workspace.data.amountMaterialCosts
              return {
                unit,
                unitCosts,
                variableDirectLaborCosts,
                variableIndirectLaborCosts,
                materialCosts,
                variableDirectLaborCostsValue: calculateCostValue(unitCosts, variableDirectLaborCosts),
                variableIndirectLaborCostsValue: calculateCostValue(unitCosts, variableIndirectLaborCosts),
                materialCostsValue: calculateCostValue(unitCosts, materialCosts),
              }
            }),
            estimate: sortedEstimateData(sortedKeyScheduleTypes, estimateData.keyScheduleTypes).map(workspace => {
              const unit = allScheduleTypes.find(item => item.id === workspace!.id)?.unit || '' // workspaceがundefinedであることはないが、sortedEstimateData関数の返り値はundefinedを許容するため、workspace!としている
              const unitCosts = isManHour ? workspace!.data.hourlyWorkerUnitCosts : workspace!.data.amountUnitCosts
              const variableDirectLaborCosts = isManHour
                ? workspace!.data.hourlyWorkerVariableDirectLaborCosts
                : workspace!.data.amountVariableDirectLaborCosts
              const variableIndirectLaborCosts = isManHour
                ? workspace!.data.hourlyWorkerVariableIndirectLaborCosts
                : workspace!.data.amountVariableIndirectLaborCosts
              const materialCosts = isManHour ? 0 : workspace!.data.amountMaterialCosts
              return {
                unit,
                unitCosts,
                variableDirectLaborCosts,
                variableIndirectLaborCosts,
                materialCosts,
                variableDirectLaborCostsValue: calculateCostValue(unitCosts, variableDirectLaborCosts),
                variableIndirectLaborCostsValue: calculateCostValue(unitCosts, variableIndirectLaborCosts),
                materialCostsValue: calculateCostValue(unitCosts, materialCosts),
              }
            }),
          }
        : {
            // 見積もり表示、または、estimateDataが存在しない時（見積データ）
            data: sortedKeyScheduleTypes.map(workspace => {
              const unit = allScheduleTypes.find(item => item.id === workspace.id)?.unit || ''
              const unitCosts = isManHour ? workspace.data.hourlyWorkerUnitCosts : workspace.data.amountUnitCosts
              const variableDirectLaborCosts = isManHour
                ? workspace.data.hourlyWorkerVariableDirectLaborCosts
                : workspace.data.amountVariableDirectLaborCosts
              const variableIndirectLaborCosts = isManHour
                ? workspace.data.hourlyWorkerVariableIndirectLaborCosts
                : workspace.data.amountVariableIndirectLaborCosts
              const materialCosts = isManHour ? 0 : workspace.data.amountMaterialCosts
              return {
                unit,
                unitCosts,
                variableDirectLaborCosts,
                variableIndirectLaborCosts,
                materialCosts,
                variableDirectLaborCostsValue: calculateCostValue(unitCosts, variableDirectLaborCosts),
                variableIndirectLaborCostsValue: calculateCostValue(unitCosts, variableIndirectLaborCosts),
                materialCostsValue: calculateCostValue(unitCosts, materialCosts),
              }
            }),
          }

    const optionProps = {
      seriesData: getGraphSeriesDataUnitCosts(graphData),
      categories: sortedKeyScheduleTypes.map(
        scheduleType => allScheduleTypes.find(item => item.id === scheduleType.id)?.name ?? ''
      ),
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }

    const options = createStackedChartOptions(optionProps)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }
      const { unitCosts, variableDirectLaborCosts, variableIndirectLaborCosts, materialCosts, unit } =
        this.point.options.custom
      const tooltipText = isManHour
        ? `<div style="text-align:right">単位原価：${unitCosts}人時 / 1${unit}</div>
            <div style="text-align:right">変動直接労務費：${variableDirectLaborCosts}%</div>
            <div style="text-align:right">変動間接労務費：${variableIndirectLaborCosts}%</div>`
        : `<div style="text-align:right">単位原価：${unitCosts}円 / 1${unit}</div>
            <div style="text-align:right">変動直接労務費：${variableDirectLaborCosts}%</div>
            <div style="text-align:right">変動間接労務費：${variableIndirectLaborCosts}%</div>
            <div style="text-align:right">資材・材料費：${materialCosts}%</div>`
      return tooltipText
    }

    return options
  }, [bopMonitoring, estimateData, getGraphSeriesDataUnitCosts, selectedBopType, isManHour, allScheduleTypes])

  return { unitCostsChartOptions }
}
