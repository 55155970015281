import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'

import { WEEKDAY_SUNDAY } from 'components/common/utils'

import useBusinessTime from './useBusinessTime'
import useQuery from './useQuery'

export const useBopReportsQuery = () => {
  const query = useQuery()
  const { getWorkDate } = useBusinessTime()

  // 営業時間前を加味して日付算出する。
  const getStartDate = useCallback(() => {
    const baseDate = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
    return baseDate.day() === WEEKDAY_SUNDAY
      ? baseDate.subtract(1, 'day').day(1).format('YYYY-MM-DD')
      : baseDate.day(1).format('YYYY-MM-DD')
  }, [getWorkDate])

  // 営業時間前を加味して日付算出する。
  const getEndDate = useCallback(() => {
    const baseDate = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
    return baseDate.day() === WEEKDAY_SUNDAY
      ? baseDate.format('YYYY-MM-DD')
      : baseDate.day(1).add(6, 'day').format('YYYY-MM-DD')
  }, [getWorkDate])

  const queryStart = useMemo(() => query.get('start') || getStartDate(), [query, getStartDate])
  const queryEnd = useMemo(() => query.get('end') || getEndDate(), [query, getEndDate])

  return {
    queryStart,
    queryEnd,
  }
}
