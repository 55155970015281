import dayjs from 'dayjs'
import { sortBy } from 'es-toolkit'
import { useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import type { BopWorkspaceData } from 'api/dashboard'

import { selectBopReportsStatus } from 'slices/bopReportsSlice'
import { selectDashboardStatus } from 'slices/dashboardSlice'

import { BOP_TYPE, ESTIMATE_POINT_PADDING } from 'components/Dashboard/utils'
import { DEFAULT_POINT_PADDING } from 'components/common/Chart/Chart'
import { createStackedChartOptions } from 'components/common/utils'

import type { Options, SeriesOptionsType } from 'highcharts'

const bopLaborCostsGraphType = {
  variableDirect: 'variableDirectLaborCosts',
  variableIndirect: 'variableIndirectLaborCosts',
  indirect: 'indirectLaborCosts',
  variableDirectEstimate: 'variableDirectLaborCostsEstimate',
  variableIndirectEstimate: 'variableIndirectLaborCostsEstimate',
  indirectEstimate: 'indirectLaborCostsEstimate',
  laborCostsPercentage: 'laborCostsPercentage',
  laborCostsPercentageEstimate: 'laborCostsPercentageEstimate',
}

type LaborCostsDataUnit = {
  variableDirectLaborCosts: number
  variableIndirectLaborCosts: number
  indirectLaborCosts: number
}

type BopLaborCostsDataProps = {
  data: LaborCostsDataUnit[]
  estimate?: LaborCostsDataUnit[]
}

type graphSeriesData = {
  series: SeriesOptionsType[]
  seriesPercentage: SeriesOptionsType[]
}

const ACTUAL_SERIES_Z_INDEX = 1
const ESTIMATE_SERIES_Z_INDEX = 0
const LABOR_COSTS_PERCENTAGE_GRAPH_Y_AXIS_MAX = 100
const POINT_PLACEMENT = 0.05
const POINT_PLACEMENT_PERCENTAGE = 0.15
const ESTIMATE_OPACITY = 0.4
const ACTUAL_VARIABLE_DIRECT_INDEX = 1
const ACTUAL_VARIABLE_INDIRECT_INDEX = 3
const ACTUAL_INDIRECT_INDEX = 5

const calLaborCostsPercentage = (value: number, costs: LaborCostsDataUnit) => {
  const totalLaborCosts = costs.variableDirectLaborCosts + costs.variableIndirectLaborCosts + costs.indirectLaborCosts
  return totalLaborCosts ? (value / totalLaborCosts) * 100 : 0
}

export const useBopLaborCosts = (selectedBopType: string, isPercentage: boolean) => {
  const { bopMonitoring } = useSelector(selectDashboardStatus, shallowEqual)
  const { bopReportsLaborCosts } = useSelector(selectBopReportsStatus, shallowEqual)

  const getGraphSeriesData = useCallback((graphData: BopLaborCostsDataProps): graphSeriesData => {
    // 実数表示
    const variableDirectData = graphData.data.map(item => item.variableDirectLaborCosts)
    const variableIndirectData = graphData.data.map(item => item.variableIndirectLaborCosts)
    const indirectData = graphData.data.map(item => item.indirectLaborCosts)
    // 割合表示
    const variableDirectPercentageData = graphData.data.map(item =>
      calLaborCostsPercentage(item.variableDirectLaborCosts, item)
    )
    const variableIndirectPercentageData = graphData.data.map(item =>
      calLaborCostsPercentage(item.variableIndirectLaborCosts, item)
    )
    const indirectPercentageData = graphData.data.map(item => calLaborCostsPercentage(item.indirectLaborCosts, item))

    const variableDirectSeriesData = variableDirectData.map((item, index) => ({
      y: item,
      custom: {
        type: bopLaborCostsGraphType.variableDirect,
        variableDirectLaborCosts: item,
        variableIndirectLaborCosts: variableIndirectData[index],
        indirectLaborCosts: indirectData[index],
      },
    }))
    const variableIndirectSeriesData = variableIndirectData.map((item, index) => ({
      y: item,
      custom: {
        type: bopLaborCostsGraphType.variableIndirect,
        variableDirectLaborCosts: variableDirectData[index],
        variableIndirectLaborCosts: item,
        indirectLaborCosts: indirectData[index],
      },
    }))
    const indirectSeriesData = indirectData.map((item, index) => ({
      y: item,
      custom: {
        type: bopLaborCostsGraphType.indirect,
        variableDirectLaborCosts: variableDirectData[index],
        variableIndirectLaborCosts: variableIndirectData[index],
        indirectLaborCosts: item,
      },
    }))

    const variableDirectPercentageSeriesData = variableDirectPercentageData.map((item, index) => {
      return {
        y: item,
        custom: {
          type: bopLaborCostsGraphType.laborCostsPercentage,
          variableDirectLaborCosts: item,
          variableIndirectLaborCosts: variableIndirectPercentageData[index],
          indirectLaborCosts: indirectPercentageData[index],
        },
      }
    })

    const variableIndirectPercentageSeriesData = variableIndirectPercentageData.map((item, index) => {
      return {
        y: item,
        custom: {
          type: bopLaborCostsGraphType.laborCostsPercentage,
          variableDirectLaborCosts: variableDirectPercentageData[index],
          variableIndirectLaborCosts: item,
          indirectLaborCosts: indirectPercentageData[index],
        },
      }
    })

    const indirectPercentageSeriesData = indirectPercentageData.map((item, index) => {
      return {
        y: item,
        custom: {
          type: bopLaborCostsGraphType.laborCostsPercentage,
          variableDirectLaborCosts: variableDirectPercentageData[index],
          variableIndirectLaborCosts: variableIndirectPercentageData[index],
          indirectLaborCosts: item,
        },
      }
    })

    const pointPlacement = graphData.estimate ? POINT_PLACEMENT : undefined
    const pointPlacementPercentage = graphData.estimate ? POINT_PLACEMENT_PERCENTAGE : undefined

    const series: SeriesOptionsType[] = [
      {
        type: 'column',
        name: '変動直接労務費',
        color: 'var(--bs-danger-stronger-middle)',
        data: variableDirectSeriesData,
        stack: bopLaborCostsGraphType.variableDirect,
        pointPlacement: pointPlacement,
        zIndex: ACTUAL_SERIES_Z_INDEX,
      },
      {
        type: 'column',
        name: '変動間接労務費',
        color: 'var(--bs-danger-middle)',
        data: variableIndirectSeriesData,
        stack: bopLaborCostsGraphType.variableIndirect,
        pointPlacement: pointPlacement,
        zIndex: ACTUAL_SERIES_Z_INDEX,
      },
      {
        type: 'column',
        name: '間接労務費',
        color: 'var(--bs-danger-pale)',
        data: indirectSeriesData,
        stack: bopLaborCostsGraphType.indirect,
        pointPlacement: pointPlacement,
        zIndex: ACTUAL_SERIES_Z_INDEX,
      },
    ]

    const seriesPercentage: SeriesOptionsType[] = [
      {
        type: 'column',
        name: '変動直接労務費',
        color: 'var(--bs-danger-stronger-middle)',
        data: variableDirectPercentageSeriesData,
        stack: bopLaborCostsGraphType.laborCostsPercentage,
        pointPlacement: pointPlacementPercentage,
        zIndex: ACTUAL_SERIES_Z_INDEX,
      },
      {
        type: 'column',
        name: '変動間接労務費',
        color: 'var(--bs-danger-middle)',
        data: variableIndirectPercentageSeriesData,
        stack: bopLaborCostsGraphType.laborCostsPercentage,
        pointPlacement: pointPlacementPercentage,
        zIndex: ACTUAL_SERIES_Z_INDEX,
      },
      {
        type: 'column',
        name: '変動労務費',
        color: 'var(--bs-danger-pale)',
        data: indirectPercentageSeriesData,
        stack: bopLaborCostsGraphType.laborCostsPercentage,
        pointPlacement: pointPlacementPercentage,
        zIndex: ACTUAL_SERIES_Z_INDEX,
      },
    ]

    if (graphData.estimate) {
      const estimateVariableDirectData = graphData.estimate.map(item => item.variableDirectLaborCosts)
      const estimateVariableIndirectData = graphData.estimate.map(item => item.variableIndirectLaborCosts)
      const estimateIndirectData = graphData.estimate.map(item => item.indirectLaborCosts)
      const estimateVariableDirectPercentageData = graphData.estimate.map(item =>
        calLaborCostsPercentage(item.variableDirectLaborCosts, item)
      )
      const estimateVariableIndirectPercentageData = graphData.estimate.map(item =>
        calLaborCostsPercentage(item.variableIndirectLaborCosts, item)
      )
      const estimateIndirectPercentageData = graphData.estimate.map(item =>
        calLaborCostsPercentage(item.indirectLaborCosts, item)
      )

      series.splice(ACTUAL_VARIABLE_DIRECT_INDEX, 0, {
        type: 'column',
        name: '変動直接労務費(見込み)',
        color: 'var(--bs-danger-stronger-middle)',
        data: estimateVariableDirectData,
        stack: bopLaborCostsGraphType.variableDirectEstimate,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacement!,
        zIndex: ESTIMATE_SERIES_Z_INDEX,
      })

      series.splice(ACTUAL_VARIABLE_INDIRECT_INDEX, 0, {
        type: 'column',
        name: '変動間接労務費(見込み)',
        color: 'var(--bs-danger-middle)',
        data: estimateVariableIndirectData,
        stack: bopLaborCostsGraphType.variableIndirectEstimate,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacement!,
        zIndex: ESTIMATE_SERIES_Z_INDEX,
      })

      series.splice(ACTUAL_INDIRECT_INDEX, 0, {
        type: 'column',
        name: '間接労務費(見込み)',
        color: 'var(--bs-danger-pale)',
        data: estimateIndirectData,
        stack: bopLaborCostsGraphType.indirectEstimate,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacement!,
        zIndex: ESTIMATE_SERIES_Z_INDEX,
      })

      seriesPercentage.splice(ACTUAL_VARIABLE_DIRECT_INDEX, 0, {
        type: 'column',
        name: '変動直接労務費(見込み)',
        color: 'var(--bs-danger-stronger-middle)',
        data: estimateVariableDirectPercentageData,
        stack: bopLaborCostsGraphType.laborCostsPercentageEstimate,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacementPercentage!,
        zIndex: ESTIMATE_SERIES_Z_INDEX,
      })
      seriesPercentage.splice(ACTUAL_VARIABLE_INDIRECT_INDEX, 0, {
        type: 'column',
        name: '変動間接労務費(見込み)',
        color: 'var(--bs-danger-middle)',
        data: estimateVariableIndirectPercentageData,
        stack: bopLaborCostsGraphType.laborCostsPercentageEstimate,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacementPercentage!,
        zIndex: ESTIMATE_SERIES_Z_INDEX,
      })
      seriesPercentage.splice(ACTUAL_INDIRECT_INDEX, 0, {
        type: 'column',
        name: '間接労務費(見込み)',
        color: 'var(--bs-danger-pale)',
        data: estimateIndirectPercentageData,
        stack: bopLaborCostsGraphType.laborCostsPercentageEstimate,
        opacity: ESTIMATE_OPACITY,
        pointPlacement: -1 * pointPlacementPercentage!,
        zIndex: ESTIMATE_SERIES_Z_INDEX,
      })
    }

    const seriesData = {
      series: series,
      seriesPercentage: seriesPercentage,
    }
    return seriesData
  }, [])

  const defineCommonOptions = useCallback(
    (options: Options) => {
      options.tooltip!.shared = true

      options.tooltip!.positioner = function (labelWidth, labelHeight, point) {
        const tooltipX = point.plotX
        const tooltipY = this.chart.plotHeight + 20
        return {
          x: tooltipX,
          y: tooltipY,
        }
      }

      if (isPercentage) {
        options.yAxis = { ...options.yAxis, max: LABOR_COSTS_PERCENTAGE_GRAPH_Y_AXIS_MAX, title: { text: '' } }
      }
    },
    [isPercentage]
  )

  // 収支モニタリングの労務費バランスタブ用のグラフデータを作成
  const summaryLaborCostsGraphOptions = useMemo(() => {
    if (!bopMonitoring) {
      return {}
    }

    const displayData =
      selectedBopType === BOP_TYPE.ESTIMATE ? bopMonitoring.estimate.workspaces : bopMonitoring.actual.workspaces

    const sortedWorkspaces = sortBy(displayData, [
      workspace =>
        workspace.data.variableDirectLaborCosts +
        workspace.data.variableIndirectLaborCosts +
        workspace.data.indirectLaborCosts,
    ]).reverse()

    const sortedEstimateData = (
      sortedWorkspacesData: BopWorkspaceData[],
      estimateWorkspacesData: BopWorkspaceData[]
    ) => {
      return sortedWorkspacesData.map(workspace => estimateWorkspacesData.find(ew => ew.id === workspace.id))
    }

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL && bopMonitoring.estimate.workspaces
        ? {
            data: sortedWorkspaces.map(workspace => ({
              variableDirectLaborCosts: workspace.data.variableDirectLaborCosts,
              variableIndirectLaborCosts: workspace.data.variableIndirectLaborCosts,
              indirectLaborCosts: workspace.data.indirectLaborCosts,
            })),
            estimate: sortedEstimateData(sortedWorkspaces, bopMonitoring.estimate.workspaces).map(workspace => ({
              variableDirectLaborCosts: workspace!.data.variableDirectLaborCosts, // workspaceがundefinedであることはないが、sortedEstimateData関数の返り値はundefinedを許容するため、workspace!としている
              variableIndirectLaborCosts: workspace!.data.variableIndirectLaborCosts,
              indirectLaborCosts: workspace!.data.indirectLaborCosts,
            })),
          }
        : {
            data: sortedWorkspaces.map(workspace => ({
              variableDirectLaborCosts: workspace.data.variableDirectLaborCosts,
              variableIndirectLaborCosts: workspace.data.variableIndirectLaborCosts,
              indirectLaborCosts: workspace.data.indirectLaborCosts,
            })),
          }

    const seriesData = getGraphSeriesData(graphData)
    const optionProps = {
      seriesData: isPercentage ? seriesData.seriesPercentage : seriesData.series,
      categories: sortedWorkspaces.map(workspace => workspace.name),
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }

    const options = createStackedChartOptions(optionProps)
    defineCommonOptions(options)

    options.tooltip!.formatter = function () {
      if (!this.point.options.custom) {
        return false
      }

      const tooltipTextUnit = isPercentage ? '%' : '円'
      return `
          <div style="text-align:right">
          変動直接労務費：${this.point.options.custom.variableDirectLaborCosts.toFixed(2).toLocaleString()}${tooltipTextUnit}<br>
          変動間接労務費：${this.point.options.custom.variableIndirectLaborCosts.toFixed(2).toLocaleString()}${tooltipTextUnit}<br>
          間接労務費：${this.point.options.custom.indirectLaborCosts.toFixed(2).toLocaleString()}${tooltipTextUnit}</div>
        `
    }

    return options
  }, [bopMonitoring, defineCommonOptions, getGraphSeriesData, isPercentage, selectedBopType])

  // 収支レポートの労務費バランスタブ用のグラフデータを作成
  const reportLaborCostsGraphOptions = useMemo(() => {
    if (!bopReportsLaborCosts) {
      return {}
    }

    const graphData =
      selectedBopType === BOP_TYPE.ACTUAL
        ? {
            data: bopReportsLaborCosts.actuals,
            estimate: bopReportsLaborCosts.estimates,
          }
        : {
            data: bopReportsLaborCosts.estimates,
          }
    const seriesData = getGraphSeriesData(graphData)
    const optionProps = {
      seriesData: isPercentage ? seriesData.seriesPercentage : seriesData.series,
      categories: bopReportsLaborCosts.actuals.map(data => dayjs(data.workDate).format('MM/DD')),
      pointPadding: graphData.estimate ? ESTIMATE_POINT_PADDING : DEFAULT_POINT_PADDING,
    }
    const options = createStackedChartOptions(optionProps)
    defineCommonOptions(options)

    options.tooltip!.formatter = function () {
      if (!this.points || !this.point.options.custom) {
        return false
      }
      const date = dayjs(this.points[0].x).format('YYYY/MM/DD')

      const tooltipTextUnit = isPercentage ? '%' : '円'
      return `
          <div style="text-align:right">${date}<br>
          変動直接労務費：${this.point.options.custom.variableDirectLaborCosts.toFixed(2).toLocaleString()}${tooltipTextUnit}<br>
          変動間接労務費：${this.point.options.custom.variableIndirectLaborCosts.toFixed(2).toLocaleString()}${tooltipTextUnit}<br>
          間接労務費：${this.point.options.custom.indirectLaborCosts.toFixed(2).toLocaleString()}${tooltipTextUnit}</div>
        `
    }

    return options
  }, [bopReportsLaborCosts, defineCommonOptions, getGraphSeriesData, isPercentage, selectedBopType])

  const updatedAtReportsLaborCosts = useMemo(
    () => bopReportsLaborCosts?.updatedAt && dayjs(bopReportsLaborCosts.updatedAt).format('YYYY/MM/DD HH:mm:ss'),
    [bopReportsLaborCosts]
  )

  return {
    summaryLaborCostsGraphOptions,
    reportLaborCostsGraphOptions,
    updatedAtReportsLaborCosts,
  }
}
