import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { BOP_EXPORT_DATA_TYPES } from 'api/bop_reports/constants'

import { getWorkspaceList } from 'slices/workspacesSlice'

import { NavMenu, BadgeLabel, CustomButton } from 'components/common'
import { PeriodSelect } from 'components/common/PeriodSelect/PeriodSelect'
import { WEEKDAY_SUNDAY } from 'components/common/utils'

import { useBopReportsQuery } from 'hooks/useBopReportsQuery'
import useBusinessTime from 'hooks/useBusinessTime'

import CsvExportDialog from './CsvExportDialog'

import type { ReactElement } from 'react'

export const BopType = {
  estimate: 'estimate',
  actual: 'actual',
}

export const toggleButtonItemList = [
  { id: BopType.estimate, label: '見込' },
  { id: BopType.actual, label: '実績' },
]

type Props = {
  selectedWorkspaceIds: number[]
  children: ReactElement
}

const pathItems = [
  {
    path: '/bop-reports/bop',
    label: '収支',
    exportDataType: BOP_EXPORT_DATA_TYPES.SUMMARY,
  },
  {
    path: '/bop-reports/profit-and-loss',
    label: '損益詳細',
    exportDataType: BOP_EXPORT_DATA_TYPES.DETAIL,
  },
  {
    path: '/bop-reports/labor-costs',
    label: '労務費バランス',
    exportDataType: BOP_EXPORT_DATA_TYPES.LABOR_COSTS,
  },
  {
    path: '/bop-reports/unit-costs',
    label: '単位原価',
    exportDataType: BOP_EXPORT_DATA_TYPES.UNIT_COSTS,
  },
]

export const BopReportsCommon = ({ selectedWorkspaceIds, children }: Props) => {
  const { queryStart, queryEnd } = useBopReportsQuery()
  const [openCsvExportDialog, setOpenCsvExportDialog] = useState(false)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const pathItem = useMemo(() => pathItems.find(({ path }) => matchPath({ path }, pathname)), [pathname])

  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const bopPeriodList = useMemo(() => {
    const baseDate = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD')))
    return [
      {
        label: '今週',
        periodStart:
          baseDate.day() === WEEKDAY_SUNDAY ? baseDate.subtract(1, 'day').day(1).toDate() : baseDate.day(1).toDate(),
        periodEnd: baseDate.day() === WEEKDAY_SUNDAY ? baseDate.toDate() : baseDate.day(1).add(6, 'day').toDate(),
        id: 'thisWeek',
      },
      {
        label: '先週',
        periodStart:
          baseDate.day() === WEEKDAY_SUNDAY
            ? baseDate.subtract(8, 'day').day(1).toDate()
            : baseDate.subtract(7, 'day').day(1).toDate(),
        periodEnd:
          baseDate.day() === WEEKDAY_SUNDAY
            ? baseDate.subtract(7, 'day').toDate()
            : baseDate.subtract(7, 'day').day(1).add(6, 'day').toDate(),
        id: 'lastWeek',
      },
      {
        label: '今月',
        periodStart: baseDate.startOf('month').toDate(),
        periodEnd: baseDate.endOf('month').toDate(),
        id: 'thisMonth',
      },
      {
        label: '先月',
        periodStart: baseDate.subtract(1, 'month').startOf('month').toDate(),
        periodEnd: baseDate.subtract(1, 'month').endOf('month').toDate(),
        id: 'lastMonth',
      },
      {
        label: '開始日と終了日を設定',
        periodStart: baseDate.toDate(),
        periodEnd: baseDate.toDate(),
        id: 'user_setting',
      },
    ]
  }, [getWorkDate])

  const handlePeriodChange = useCallback(
    (start: Date, end: Date) => {
      navigate(`?start=${dayjs(start).format('YYYY-MM-DD')}&end=${dayjs(end).format('YYYY-MM-DD')}`, {
        replace: true,
      })
    },
    [navigate]
  )

  return (
    <NavMenu>
      <div className="m-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div className="font-x-large fw-bold">収支レポート</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={pathItem?.label || ''} />
            </div>
          </div>
          <div className="d-flex  ms-auto">
            <CustomButton className="me-2" outline onClick={() => setOpenCsvExportDialog(true)}>
              CSVエクスポート
            </CustomButton>
            <PeriodSelect
              period={{ start: dayjs(queryStart).toDate(), end: dayjs(queryEnd).toDate() }}
              periodListData={bopPeriodList}
              minDate={dayjs().subtract(425, 'days').toDate()}
              maxDate={dayjs().add(45, 'days').toDate()}
              onChange={handlePeriodChange}
            />
          </div>
        </div>
        <div>{children}</div>

        <CsvExportDialog
          open={openCsvExportDialog}
          setOpen={setOpenCsvExportDialog}
          selectedWorkspaceIds={selectedWorkspaceIds}
          exportDataType={pathItem?.exportDataType || BOP_EXPORT_DATA_TYPES.SUMMARY}
        />
      </div>
    </NavMenu>
  )
}
