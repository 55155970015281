import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/bop_reports'
import type {
  BopMonitoringReportParam,
  BopReportsBopResponse,
  BopReportsLaborCostsResponse,
  BopReportsProfitAndLossResponse,
  BopReportsUnitCostsResponse,
  BopReportUnitCostsParam,
  CheckExportDataReadyResponse,
  CreateBopExportDataInfo,
} from 'api/bop_reports/types'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type BopReportsState = {
  isRequesting: boolean
  errorMessage: string
  bopReports: BopReportsProfitAndLossResponse | undefined
  bopReportsBop: BopReportsBopResponse | undefined
  bopReportsLaborCosts: BopReportsLaborCostsResponse | undefined
  bopReportsUnitCosts: BopReportsUnitCostsResponse | undefined
  downloadUrl?: string
}

const initialState: BopReportsState = {
  isRequesting: false,
  errorMessage: '',
  bopReports: undefined,
  bopReportsBop: undefined,
  bopReportsLaborCosts: undefined,
  bopReportsUnitCosts: undefined,
  downloadUrl: undefined,
}

export const bopReportsSlice = createSlice({
  name: 'bopReports',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getBopReportsSuccess: (state, action: PayloadAction<BopReportsBopResponse>) => {
      state.isRequesting = false
      state.bopReportsBop = action.payload
    },
    getBopReportsProfitAndLossSuccess: (state, action: PayloadAction<BopReportsProfitAndLossResponse>) => {
      state.isRequesting = false
      state.bopReports = action.payload
    },
    getBopReportsLaborCostsSuccess: (state, action: PayloadAction<BopReportsLaborCostsResponse>) => {
      state.isRequesting = false
      state.bopReportsLaborCosts = action.payload
    },
    getBopReportsUnitCostsSuccess: (state, action: PayloadAction<BopReportsUnitCostsResponse>) => {
      state.isRequesting = false
      state.bopReportsUnitCosts = action.payload
    },
    getExportDataUrlSuccess: (state, action: PayloadAction<CheckExportDataReadyResponse>) => {
      state.isRequesting = false
      state.downloadUrl = action.payload.downloadUrl
    },
    resetExportDataUrl: state => (state.downloadUrl = undefined),
  },
})

export const {
  startRequest,
  apiFailure,
  getBopReportsSuccess,
  getBopReportsProfitAndLossSuccess,
  getBopReportsLaborCostsSuccess,
  getBopReportsUnitCostsSuccess,
  getExportDataUrlSuccess,
  resetExportDataUrl,
} = bopReportsSlice.actions

export const getBopReports =
  (data: BopMonitoringReportParam): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBopReportsBop(commonParams(getState), data)
      .then((res: BopReportsBopResponse) => dispatch(getBopReportsSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getBopReportsProfitAndLoss =
  (from: string, to: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBopReportsProfitAndLoss(commonParams(getState), from, to)
      .then((res: BopReportsProfitAndLossResponse) => dispatch(getBopReportsProfitAndLossSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getBopReportsLaborCosts =
  (data: BopMonitoringReportParam): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBopReportsLaborCosts(commonParams(getState), data)
      .then((res: BopReportsLaborCostsResponse) => dispatch(getBopReportsLaborCostsSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getBopReportsUnitCosts =
  (data: BopReportUnitCostsParam): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getBopReportsUnitCosts(commonParams(getState), data)
      .then((res: BopReportsUnitCostsResponse) => dispatch(getBopReportsUnitCostsSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getBopExportDataUrl =
  (data: CreateBopExportDataInfo): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }
    dispatch(Spinner.start())
    try {
      const { requestId } = await API.createBopExportData(commonParams(getState), data)
      if (!requestId) {
        return
      }
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const exportDataResponse = await API.getBopExportData(commonParams(getState), requestId)
        if (exportDataResponse?.downloadUrl) {
          return dispatch(getExportDataUrlSuccess(exportDataResponse))
        }
      }
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const selectBopReportsStatus = (state: RootState) => ({ ...state.bopReports })

export default bopReportsSlice.reducer
