import dayjs from 'dayjs'
import { isEqual, mean } from 'es-toolkit'
import { floor } from 'es-toolkit/compat'
import { useMemo, useEffect, useCallback, useReducer } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Col, Input, Label, Row } from 'reactstrap'

import type { KeyScheduleTypes, UnitCostsData } from 'api/bop_reports/types'

import { getBopReportsUnitCosts, selectBopReportsStatus } from 'slices/bopReportsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getRelatedScheduleType, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectUsersStatus, getDisplayFilter, updateDisplayFilter } from 'slices/usersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'

import { GraphSelectButton } from 'components/Dashboard/GraphSelectButton'
import { colorTypeToCode, toLighterColorCode } from 'components/Dashboard/utils'
import { AmountCard, BadgeButton, Chart, GroupRadioButton, NotSelectedPlaceholder } from 'components/common'
import { UpdateLabel } from 'components/common/UpdateLabel/UpdateLabel'
import type { Series } from 'components/common/types'
import {
  ColorTypes,
  PlaceholderTypes,
  createLineChartOptions,
  createStackedChartOptions,
} from 'components/common/utils'

import { useBopReportsQuery } from 'hooks/useBopReportsQuery'
import useBusinessTime from 'hooks/useBusinessTime'
import { useGraphSelectButton } from 'hooks/useGraphSelectButton'

import { BopReportsCommon, BopType, toggleButtonItemList } from './BopReportsCommon'

import type { SeriesColumnOptions } from 'highcharts'

// グラフの間接労務費の色を薄くするための定数
const LIGHT_AMOUNT = 30
const UNIT_COSTS_POINT_PADDING = 0

const createEmptyData = (date: string) => ({
  date,
  opacity: 1,
  materialCosts: 0,
  variableDirectLaborCosts: 0,
  variableIndirectLaborCosts: 0,
  unitCosts: 0,
})

type UnitCostsType = {
  date: string
  opacity: number
  materialCosts: number
  variableDirectLaborCosts: number
  variableIndirectLaborCosts: number
  unitCosts: number
}

type ReducerState = {
  submitted: boolean
  bopType: string
  isEstimateChecked: boolean
  isHourlyWorker: boolean
  isLineGraph: boolean
}

const ACTION_TYPE = {
  SAVE: 'save',
  CHANGE_BOP_TYPE: 'changeBopType',
  CHANGE_CHECKED_ESTIMATE: 'changeCheckedEstimate',
  CHANGE_HOURLY_WORKER: 'changeHourlyWorker',
  CHANGE_LINE_GRAPH: 'changeLineGraph',
} as const

const SAVE_PAYLOAD_TYPE = {
  RUNNING: 'running',
  FINISHED: 'finished',
} as const

type SavePayloadType = (typeof SAVE_PAYLOAD_TYPE)[keyof typeof SAVE_PAYLOAD_TYPE]

type ReducerAction =
  | { type: typeof ACTION_TYPE.SAVE; payload: SavePayloadType }
  | { type: typeof ACTION_TYPE.CHANGE_BOP_TYPE; payload: string }
  | { type: typeof ACTION_TYPE.CHANGE_CHECKED_ESTIMATE; payload: boolean }
  | { type: typeof ACTION_TYPE.CHANGE_HOURLY_WORKER; payload: boolean }
  | { type: typeof ACTION_TYPE.CHANGE_LINE_GRAPH; payload: boolean }

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    // 表示作業を保存中/完了を切り替える
    case ACTION_TYPE.SAVE:
      return { ...state, submitted: action.payload === SAVE_PAYLOAD_TYPE.RUNNING }
    // 実績と見込みを切り替える
    case ACTION_TYPE.CHANGE_BOP_TYPE:
      return { ...state, bopType: action.payload }
    // 見込みを含んだ予測表示を切り替える
    case ACTION_TYPE.CHANGE_CHECKED_ESTIMATE:
      return { ...state, isEstimateChecked: action.payload }
    // 人時表示を切り替える
    case ACTION_TYPE.CHANGE_HOURLY_WORKER:
      return { ...state, isHourlyWorker: action.payload }
    // 折れ線グラフ表示を切り替える
    case ACTION_TYPE.CHANGE_LINE_GRAPH:
      return { ...state, isLineGraph: action.payload }
    default:
      throw Error('Unknown action.')
  }
}

type TooltipProps = {
  date: string
  name: string
  unit: string
  amountUnitCosts: number
  variableDirectLaborCosts: number
  variableIndirectLaborCosts: number
  materialCosts: number
}

const initialReducerState: ReducerState = {
  submitted: false,
  bopType: BopType.actual,
  isEstimateChecked: false,
  isHourlyWorker: false,
  isLineGraph: false,
}

export const BopReportsUnitCosts = () => {
  const [state, dispatchState] = useReducer(reducer, initialReducerState)

  const dispatch = useDispatch()

  const { allScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { bopReportsUnitCosts } = useSelector(selectBopReportsStatus, shallowEqual)
  const { displayFilter, errorMessage, isRequesting } = useSelector(selectUsersStatus, shallowEqual)
  const { partialWorkspaces } = useSelector(selectWorkspacesStatus, shallowEqual)

  const { queryStart, queryEnd } = useBopReportsQuery()
  const { keyItems, selectedGraphs, setSelectedGraphs } = useGraphSelectButton()
  const { getWorkDate } = useBusinessTime()

  const workspaceIds = useMemo(() => partialWorkspaces.map(w => w.id), [partialWorkspaces])
  const todayWorkDate = useMemo(() => getWorkDate(dayjs().format('YYYY-MM-DD')), [getWorkDate])

  useEffect(() => {
    dispatch(getBopReportsUnitCosts({ from: queryStart, to: queryEnd, displayFilter: true }))
  }, [dispatch, queryStart, queryEnd])

  useEffect(() => {
    dispatch(getRelatedScheduleType())
    dispatch(getDisplayFilter())
  }, [dispatch])

  useEffect(() => {
    if (!displayFilter?.bopReport.workspaceData) {
      return
    }
    setSelectedGraphs(
      displayFilter.bopReport.workspaceData
        .flatMap(wd => wd.scheduleTypeData)
        .filter(st => st.isFilteredInUnitCosts)
        .map(scheduleType => scheduleType.id)
    )
  }, [displayFilter, setSelectedGraphs])

  // 人時/金額表示の切り替えによるデータの再計算
  const transformData = useCallback(
    (data: UnitCostsData) => {
      if (state.isHourlyWorker) {
        return {
          date: data.workDate,
          unitCosts: data.hourlyWorkerUnitCosts,
          variableDirectLaborCosts: data.hourlyWorkerVariableDirectLaborCosts,
          variableIndirectLaborCosts: data.hourlyWorkerVariableIndirectLaborCosts,
          materialCosts: 0,
        }
      }
      return {
        date: data.workDate,
        unitCosts: data.amountUnitCosts,
        variableDirectLaborCosts: data.amountVariableDirectLaborCosts,
        variableIndirectLaborCosts: data.amountVariableIndirectLaborCosts,
        materialCosts: data.amountMaterialCosts,
      }
    },
    [state.isHourlyWorker]
  )

  const getDisplayData = useCallback(
    (st: KeyScheduleTypes, unitCostsList: UnitCostsType[]) => {
      const scheduleType = allScheduleTypes.find(ast => ast.id === st.scheduleTypeId)
      if (!scheduleType) {
        return {
          id: st.scheduleTypeId,
          name: '',
          color: ColorTypes.Silver,
          unit: '',
          avg: 0,
          data: [],
        }
      }
      const formattedUnitCosts = unitCostsList.map(d => d.unitCosts)
      const meanUnitCosts = mean(formattedUnitCosts) || 0
      return {
        id: st.scheduleTypeId,
        name: `${scheduleType.workspaceName}/${scheduleType.name}`,
        color: scheduleType.color,
        unit: `${state.isHourlyWorker ? '人時' : '円'}/1${scheduleType.unit}`,
        avg: floor(meanUnitCosts, 2),
        data: unitCostsList,
      }
    },
    [allScheduleTypes, state.isHourlyWorker]
  )

  const xAxisList = useMemo(() => {
    // 過去日付で作業が存在しない可能性があるのでクエリの日付範囲でx軸のデータを作成する
    const xStart = dayjs(queryStart)
    const xEnd = dayjs(queryEnd)
    return Array.from({ length: xEnd.diff(xStart, 'day') + 1 }, (_, i) => xStart.add(i, 'day').format('YYYY-MM-DD'))
  }, [queryStart, queryEnd])

  const displayData = useMemo(() => {
    if (!bopReportsUnitCosts) {
      return []
    }

    return bopReportsUnitCosts.estimates.map((st, index) => {
      // 日付毎にデータを作成し、該当日にデータがない場合は空データを追加する
      const data = xAxisList.map(date => {
        // 見込
        if (state.bopType === BopType.estimate) {
          const stData = st.data.find(d => d.workDate === date)
          return stData ? { ...transformData(stData), opacity: 1 } : createEmptyData(date)
        }
        // 実績
        if (!state.isEstimateChecked) {
          const stData = bopReportsUnitCosts.actuals[index].data.find(d => d.workDate === date)
          return stData ? { ...transformData(stData), opacity: 1 } : createEmptyData(date)
        }

        // 実績（見込を含んだ予測表示）：過去日付では実績、当日以降は見込からデータを取得する
        const isPast = dayjs(date).isBefore(todayWorkDate, 'day')
        const stData = (isPast ? bopReportsUnitCosts.actuals[index] : st).data.find(d => d.workDate === date)
        return stData ? { ...transformData(stData), opacity: isPast ? 1 : 0.5 } : createEmptyData(date)
      })
      return getDisplayData(st, data)
    })
  }, [
    bopReportsUnitCosts,
    xAxisList,
    getDisplayData,
    state.bopType,
    state.isEstimateChecked,
    todayWorkDate,
    transformData,
  ])

  const disabled = useMemo(() => keyItems.length === 0, [keyItems])

  const handleToggleChange = useCallback((id: string) => {
    dispatchState({ type: ACTION_TYPE.CHANGE_BOP_TYPE, payload: id })
  }, [])

  useEffect(() => {
    if (!state.submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      dispatch(showError())
    }
    dispatchState({ type: ACTION_TYPE.SAVE, payload: SAVE_PAYLOAD_TYPE.FINISHED })
  }, [isRequesting, errorMessage, dispatch, state.submitted])

  const handleScheduleSaveButtonClick = useCallback(() => {
    if (!displayFilter) {
      return
    }

    dispatchState({ type: ACTION_TYPE.SAVE, payload: SAVE_PAYLOAD_TYPE.RUNNING })
    const updateScheduleTypes = displayFilter.bopReport.workspaceData.map(df => {
      const scheduleTypeData = df.scheduleTypeData.map(scheduleType => ({
        ...scheduleType,
        isFilteredInUnitCosts: selectedGraphs.includes(scheduleType.id),
      }))
      return { ...df, scheduleTypeData }
    })

    dispatch(updateDisplayFilter({ bopReport: { workspaceData: updateScheduleTypes } }))
  }, [dispatch, displayFilter, selectedGraphs])

  const createTooltip = useCallback(
    (fp: TooltipProps) => {
      return `<div style="text-align:right">
      ${dayjs(fp.date).format('YYYY/MM/DD')}
      <br>${fp.name}
      <br>単位原価：${fp.amountUnitCosts}${fp.unit}
      <br>変動直接労務費率：${fp.variableDirectLaborCosts}%
      <br>変動間接労務費率：${fp.variableIndirectLaborCosts}%
      ${!state.isHourlyWorker ? `<br>資材・材料費率：${fp.materialCosts}%` : ''}
    </div>`
    },
    [state.isHourlyWorker]
  )

  const chartOptions = useMemo(() => {
    const yAxisOption = {
      max: Math.max(...displayData.map(item => Math.max(...item.data.map(d => d.unitCosts)))),
      min: 0,
      title: {
        text: undefined,
      },
    }
    // 折れ線グラフのオプション
    if (state.isLineGraph) {
      const yAxis = displayData.reduce<Series[]>((acc, cur, index) => {
        const series = acc[index] ?? {
          type: 'line',
          color: colorTypeToCode(cur.color),
          name: cur.name,
          data: [],
          custom: { unit: cur.unit, data: cur.data },
        }
        // 単位原価が0の点は描画しない
        series.data = [...series.data, ...cur.data.map(d => d.unitCosts || null)]
        acc[index] = series
        return acc
      }, [])
      const options = {
        ...createLineChartOptions(
          {
            xAxis: {
              data: xAxisList,
            },
            yAxis,
          },
          'Solid'
        ),
        chart: {
          // 折れ線グラフと積み立てグラフの縦幅を合わせるために設定
          alignTicks: false,
        },
        yAxis: yAxisOption,
      }
      options.tooltip!.formatter = function () {
        const row = (this.series.options.custom?.data || []).find((r: { date: string }) => r.date === this.x)
        if (!row) {
          return ''
        }
        return createTooltip({
          date: row.date,
          name: this.series.name,
          unit: this.series.options.custom?.unit || '',
          amountUnitCosts: row.unitCosts,
          variableDirectLaborCosts: row.variableDirectLaborCosts,
          variableIndirectLaborCosts: row.variableIndirectLaborCosts,
          materialCosts: row.materialCosts,
        })
      }
      return options
    }
    // 積み立てグラフのオプション
    const seriesData: SeriesColumnOptions[] = displayData
      .map(item => ({
        name: '直接労務費',
        data: item.data.map(d => ({
          // 単位原価が0の点は描画しない
          y: d.unitCosts && (d.unitCosts * d.variableDirectLaborCosts) / 100,
          custom: {
            date: d.date,
            name: item.name,
            unit: item.unit,
            amountUnitCosts: d.unitCosts,
            variableDirectLaborCosts: d.variableDirectLaborCosts,
            variableIndirectLaborCosts: d.variableIndirectLaborCosts,
            materialCosts: d.materialCosts,
          },
          opacity: d.opacity,
        })),
        stack: item.id.toString(),
        type: 'column' as const,
        color: colorTypeToCode(item.color),
        states: {
          hover: {
            enabled: false,
          },
        },
      }))
      .concat(
        displayData.map(item => ({
          name: '間接労務費',
          data: item.data.map(d => ({
            y: d.unitCosts && (d.unitCosts * d.variableIndirectLaborCosts) / 100,
            custom: {
              date: d.date,
              name: item.name,
              unit: item.unit,
              amountUnitCosts: d.unitCosts,
              variableDirectLaborCosts: d.variableDirectLaborCosts,
              variableIndirectLaborCosts: d.variableIndirectLaborCosts,
              materialCosts: d.materialCosts,
            },
            opacity: d.opacity,
          })),
          stack: item.id.toString(),
          type: 'column',
          color: toLighterColorCode(item.color, LIGHT_AMOUNT),
          states: {
            hover: {
              enabled: false,
            },
          },
        }))
      )
      .concat(
        displayData.map(item => ({
          name: '資材・材料費',
          data: item.data.map(d => ({
            y: d.unitCosts && d.materialCosts && (d.unitCosts * d.materialCosts) / 100,
            custom: {
              date: d.date,
              name: item.name,
              unit: item.unit,
              amountUnitCosts: d.unitCosts,
              variableDirectLaborCosts: d.variableDirectLaborCosts,
              variableIndirectLaborCosts: d.variableIndirectLaborCosts,
              materialCosts: d.materialCosts,
            },
            opacity: d.opacity,
          })),
          stack: item.id.toString(),
          type: 'column',
          color: '#dee2e6',
          states: {
            hover: {
              enabled: false,
            },
          },
        }))
      )
    const options = {
      ...createStackedChartOptions({
        categories: xAxisList.map(date => dayjs(date).format('MM/DD')),
        seriesData,
        pointPadding: UNIT_COSTS_POINT_PADDING,
      }),
      chart: {
        // 折れ線グラフと積み立てグラフの縦幅を合わせるために設定
        alignTicks: false,
      },
      yAxis: yAxisOption,
    }
    options.tooltip!.formatter = function () {
      const customData = this.point.options.custom
      if (!customData) {
        return ''
      }
      return createTooltip({
        date: customData.date,
        name: customData.name,
        unit: customData.unit,
        amountUnitCosts: customData.amountUnitCosts,
        variableDirectLaborCosts: customData.variableDirectLaborCosts,
        variableIndirectLaborCosts: customData.variableIndirectLaborCosts,
        materialCosts: customData.materialCosts,
      })
    }
    options.tooltip!.positioner = function (this, labelWidth, _labelHeight, point) {
      const chartWidth = this.chart.plotWidth
      const tooltipX = point.plotX
      const tooltipY = this.chart.plotHeight + 20
      // 右端のツールチップが見切れる場合、左にずらす
      if (tooltipX + labelWidth > chartWidth) {
        return {
          x: tooltipX - labelWidth / 2,
          y: tooltipY,
        }
      }
      return {
        x: tooltipX,
        y: tooltipY,
      }
    }
    return options
  }, [xAxisList, displayData, createTooltip, state.isLineGraph])

  const graphBadges = useMemo(
    () =>
      displayData.map(scheduleType => ({
        color: scheduleType.color,
        key: scheduleType.id,
        label: scheduleType.name,
        disabled: true,
      })),
    [displayData]
  )

  const materialItem = useMemo(
    () => [{ color: ColorTypes.Silver, key: 0, label: '資材・材料費', disabled: true, bgColor: 'gray-300' }],
    []
  )

  const handleScheduleTypesSelect = useCallback(
    (scheduleTypeIds: number[]) => {
      if (isEqual(scheduleTypeIds, selectedGraphs)) {
        return
      }
      setSelectedGraphs(scheduleTypeIds)
      dispatch(getBopReportsUnitCosts({ from: queryStart, to: queryEnd, scheduleTypeIds: scheduleTypeIds.join() }))
    },
    [dispatch, queryEnd, queryStart, selectedGraphs, setSelectedGraphs]
  )

  return (
    <BopReportsCommon selectedWorkspaceIds={workspaceIds}>
      <Card className="mt-2">
        <CardBody className="p-4">
          <div className="d-flex align-items-baseline">
            <CardTitle className="fw-bold font-large text-nowrap">単位原価</CardTitle>
            <div className="w-100 d-flex justify-content-end">
              <GraphSelectButton
                items={keyItems}
                selectedGraphs={selectedGraphs}
                onChange={scheduleTypes => handleScheduleTypesSelect(scheduleTypes)}
                disabled={disabled}
                text="表示キー作業"
                onSaveButtonClick={handleScheduleSaveButtonClick}
              />
            </div>
          </div>
          {selectedGraphs.length === 0 ? (
            <NotSelectedPlaceholder type={PlaceholderTypes.unitCosts} />
          ) : (
            <>
              <div className="d-flex align-items-baseline mt-2">
                <GroupRadioButton
                  items={toggleButtonItemList}
                  initSelectedId={toggleButtonItemList[1].id}
                  onChange={handleToggleChange}
                />
                {state.bopType === BopType.actual && (
                  <div className="form-check ms-3">
                    <Input
                      className="form-check-input"
                      id="expectation"
                      checked={state.isEstimateChecked}
                      type="checkbox"
                      onChange={e =>
                        dispatchState({ type: ACTION_TYPE.CHANGE_CHECKED_ESTIMATE, payload: e.target.checked })
                      }
                    />
                    <Label className="form-check-label mb-0">見込を含んだ予測表示</Label>
                  </div>
                )}
              </div>
              <div className="d-flex my-3">
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    id="hourlyWorker"
                    checked={state.isHourlyWorker}
                    type="checkbox"
                    onChange={e => dispatchState({ type: ACTION_TYPE.CHANGE_HOURLY_WORKER, payload: e.target.checked })}
                  />
                  <Label className="form-check-label mb-0">人時表示</Label>
                </div>
                <div className="form-check ms-3">
                  <Input
                    className="form-check-input"
                    id="lineGraph"
                    checked={state.isLineGraph}
                    type="checkbox"
                    onChange={e => dispatchState({ type: ACTION_TYPE.CHANGE_LINE_GRAPH, payload: e.target.checked })}
                  />
                  <Label className="form-check-label mb-0">折れ線グラフ</Label>
                </div>
              </div>
              <Chart options={chartOptions!} />
              <div className="d-flex row mx-0">
                <BadgeButton items={graphBadges} />
                {!state.isHourlyWorker && <BadgeButton items={materialItem} />}
              </div>
              <div className="my-2">平均単位原価</div>
              <Row lg={4} className="g-3 mb-2">
                {displayData?.map(scheduleType => (
                  <Col key={`report-card-col-${scheduleType.id}`}>
                    <AmountCard
                      amount={scheduleType.avg.toString()}
                      badgeLabel={scheduleType.name}
                      badgeColor={scheduleType.color}
                      unit={scheduleType.unit}
                    />
                  </Col>
                ))}
              </Row>
              <UpdateLabel updatedAt={bopReportsUnitCosts?.updatedAt} />
            </>
          )}
        </CardBody>
      </Card>
    </BopReportsCommon>
  )
}
